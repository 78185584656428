import { showDeparturesLine } from "../../modules/map/lines/utils";
import {
    clearMap,
    hideLayersSection,
    displayLayersSection,
    toggleLayersByIds,
    changeOpacityLayer,
    removeByLayerKey
} from "./core/clearMap";

export * from './core/clearMarker';
export * from './core/reverseMarker';
export * from './core/clearMap';
export * from './core/locationFound';
export * from './core/geolocation';

export function initializeMap(parameters) {
    initMapEventListeners();

    const center = [parameters.latitude, parameters.longitude - 0.1];

    Kisio.layerGroup = {
        home: {
            homeLayers: new L.layerGroup(),
        },
        journey: {
            journeyLayers: new L.layerGroup(),
        },
        schedule: {
            scheduleLayers: new L.layerGroup(),
        },
        traffic: {
        },
        proximity: {
            proximityLayers: new L.LayerGroup(),
            poiGroupLayer: new L.LayerGroup(),
            stopPointGroupLayer: new L.LayerGroup(),
        },
    };

    return L.map('mapid', {
        center: center,
        zoom: parameters.zoom,
        zoomControl: parameters.zoom_control,
        keyboard: false,
    });
}

function initMapEventListeners() {
    document.addEventListener('show_line_in_popup_event', function (event) {
        if (event.detail.line && event.detail.object) {

            if (event.detail.previousEvent.sourceTarget.options.fillOpacity !== 1) {
                return;
            }

            window.leaflet.map.closePopup();

            setTimeout(() => {
                let popupContent = showDeparturesLine(event.detail.line, event.detail.object);
                let e = event.detail.previousEvent;
                // let popupContent = showLinesInPopup(line, sp);
                e.target.bindPopup(popupContent).openPopup([e.latlng.lat, e.latlng.lng]);
              JKisio('#leaflet-popup').focus();
            }, 100)
        }
    });

    document.addEventListener('clear_map', (event) => {
        let clean_data = false;

        if (event.detail.clean_data) {
            clean_data = event.detail.clean_data;
        }

        clearMap(clean_data);
    });

    document.body.addEventListener("display_layers_on_map_event", function (e) {
        toggleLayersByIds(e.detail.linesId, e.detail.section, e.detail.layerGroupId);
    });
    
    document.body.addEventListener('hide_layers_section_event', function (e) {
        let clean_data = false;

        if (e.detail.clean_data) {
            clean_data = e.detail.clean_data;
        }

        if (!e.detail.section) {
            return;
        }

        hideLayersSection(e.detail.section, clean_data);
    });

    document.addEventListener('remove_by_layer_key', function (e) {
        let clean_data = false;

        if (e.detail.target_layer_key) {
            clean_data = e.detail.target_layer_key;
        }

        if (e.detail.clean_data) {
            clean_data = e.detail.clean_data;
        }

        if (!e.detail.target_layer_key) {
            return;
        }

        removeByLayerKey(e.detail.target_layer_key, clean_data);
    })

    document.body.addEventListener('display_layers_section_event', function (e) {
        displayLayersSection(e.detail.section);
    });

    document.body.addEventListener('opacity_layers_section_event', function (e) {
        changeOpacityLayer(e.detail.section, e.detail.layer, e.detail.opacity);
    });
}

export function resetCenterMap (parameters) {
    const center = new L.LatLng(parameters.latitude, parameters.longitude - 0.1);
    
    window.leaflet.map.panTo(center);
    window.leaflet.map.setZoom(parameters.zoom);
}
